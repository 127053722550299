@font-face {
  font-family: "dots";
  src: url("/assets/fonts/dots.ttf");
}
* {
  font-family: dots, arial;
  text-transform: uppercase;
  font-weight: normal;
  box-sizing: border-box;
}

html {
  background: black;
  background: url("/assets/images/background.jpg");
  background-size: cover;
  text-align: center;
  height: 100%;
  min-width: 700px;
}